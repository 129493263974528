import { CaretIcon, Label } from '@animoto/components';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import {
  arrow,
  hidden,
  pageNavigation,
  pageNumber
} from './BlogPageNavigation.module.css';

export default function BlogPageNavigation({
  className,
  currentPage,
  nextUrl,
  previousUrl
}) {
  const previousStyles = classNames(arrow, {
    [hidden] : !previousUrl
  });
  const nextStyles = classNames(arrow, {
    [hidden] : !nextUrl
  });
  const pageNavigationClasses = classNames(className, pageNavigation);
  return (
    <div className={pageNavigationClasses}>
      <a className={`s-previous ${previousStyles}`} href={previousUrl}>
        <CaretIcon color="white" direction="left" size="small" />
      </a>
      <Label className={pageNumber}>Page {currentPage}</Label>
      <a className={`s-next ${nextStyles}`} href={nextUrl}>
        <CaretIcon color="white" direction="right" size="small" />
      </a>
    </div>
  );
}

BlogPageNavigation.propTypes = {
  className   : PropTypes.string,
  currentPage : PropTypes.number.isRequired,
  nextUrl     : PropTypes.string,
  previousUrl : PropTypes.string
};

BlogPageNavigation.defaultProps = {
  className   : null,
  nextUrl     : '',
  previousUrl : ''
};
