exports.generatePaginatedPosts = function({
  createPage,
  id,
  pageTitle,
  count,
  pathPrefix,
  component,
  ...rest
}) {
  const postsPerPage = 6;
  const pageCount = Math.ceil(count / postsPerPage);
  Array.from({ length : pageCount }).forEach((_, i) => {
    createPage({
      path    : i === 0 ? pathPrefix : `${pathPrefix}/page/${i + 1}`,
      component,
      context : {
        id,
        pathPrefix,
        limit : postsPerPage,
        skip  : i * postsPerPage,
        pageTitle,
        ...rest
      }
    });
  });
};

exports.generatePaginatedUrls = function({
  pathPrefix,
  currentPage,
  hasNextPage,
  hasPreviousPage
}) {
  const nextUrl = hasNextPage ? `${pathPrefix}/page/${(currentPage + 1)}` : '';
  let previousUrl = currentPage === 2 ? pathPrefix : `${pathPrefix}/page/${(currentPage - 1)}`;
  previousUrl = hasPreviousPage ? previousUrl : '';
  return {
    currentPage,
    hasNextPage,
    hasPreviousPage,
    nextUrl,
    previousUrl
  };
};
